import React from 'react'
import {useAsync} from 'react-async'
import {bootstrapAppData} from '../utils/bootstrap.js'
import * as authClient from '../utils/auth-client'
import {FullPageSpinner} from '../components/lib'

const AuthContext = React.createContext()
function AuthProvider(props) {
  const [firstAttempFinished, setFirstAttempFinished] = React.useState(false)
  const {
    data = {user: null, listItems: []},
    error,
    isRejected,
    isPending,
    isSettled,
    reload,
  } = useAsync({
    promiseFn: bootstrapAppData,
  })

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttempFinished(true)
    }
  }, [isSettled])

  if (!firstAttempFinished) {
    if (isPending) {
      return <FullPageSpinner />
    }
    if (isRejected) {
      return (
        <div>
          <p>Uh oh... There is a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
        </div>
      )
    }
  }

  const login = () => authClient.login().then(reload)
  /* const register = form => authClient.register(form).then(reload) */
  const logout = () => authClient.logout().then(reload)

  return (
    <AuthContext.Provider
      value={{data, login, logout /*  register */}}
      {...props}
    />
  )
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined)
    throw new Error(`useAuth must be used within a AuthProvider`)

  return context
}

export {AuthProvider, useAuth, AuthContext}
