import {getToken} from 'utils/auth-client'
const axios = require('axios')

let instance = null
function kebabCaseToCamel(str) {
  // return str.replace( /(\-\w)/g, (matches) => matches[1].toUpperCase())
  return str.replace(/(\\w)/g, matches => matches[1].toUpperCase())
}

class API {
  constructor() {
    if (!instance) {
      instance = this
      this._endpoints = {}
      this._apiInstance = axios.create({
        timeout: 30000,
        headers: {
          authorization: `bearer ${getToken()}`,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      })
      this.setToken = token =>
        (this._apiInstance.defaults.headers.authorization = `bearer ${token}`)
      this.handleErrors()
    }
    return instance
  }

  /**
   * Create and store a single entity's _endpoints
   * @param {A entity Object} entity
   */
  createEntity(entity) {
    /**
     * If there is a - in the entity.name, then change it
     * to camelCase. E.g
     * ```
     * myApi.createEntity({ name : 'foo-bar'})
     * myApi._endpoints.fooBar.getAll(...)
     */

    const name = kebabCaseToCamel(entity.name)
    if (!this._endpoints[name]) {
      this._endpoints[name] = this.createBasicCRUDEndpoints(entity)
    }

    return this._endpoints[name]
  }

  createEntities(arrayOfEntity) {
    arrayOfEntity.forEach(this.createEntity.bind(this))
  }

  /**
   * Create the basic _endpoints handlers for CRUD operations
   * @param {A entity Object} entity
   */
  createBasicCRUDEndpoints({name}) {
    let endpoints = {}

    const resourceURL = `${process.env.REACT_APP_API_URL}/${name}`

    endpoints.getAll = (query = {}, config = {}) =>
      this._apiInstance.get(
        `${resourceURL}`,
        Object.assign({params: query}, config),
      )

    endpoints.getOne = ({id}, query = {}, config = {}, path = '') =>
      this._apiInstance.get(
        `${resourceURL}/${id}${path}`,
        Object.assign({params: query}, config),
      )

    endpoints.create = (toCreate, config = {}, path = '') =>
      this._apiInstance.post(
        path ? `${resourceURL}/${path}` : resourceURL,
        toCreate,
        config,
      )

    endpoints.update = (toUpdate, config = {}, path = '') =>
      this._apiInstance.put(
        path ? `${resourceURL}/${path}` : `${resourceURL}/${toUpdate._id}`,
        toUpdate,
        config,
      )

    endpoints.patch = ({id}, toPatch, config = {}, path = '/') =>
      this._apiInstance.patch(`${resourceURL}/${id}${path}`, toPatch, config)

    endpoints.put = ({id}, toPut, config = {}, path = '/') =>
      this._apiInstance.put(`${resourceURL}/${id}${path}`, toPut, config)

    endpoints.delete = ({id}, query = {}, config = {}) =>
      this._apiInstance.delete(
        `${resourceURL}/${id}`,
        Object.assign({params: query}, config),
      )

    return endpoints
  }

  get apiInstance() {
    return this._apiInstance
  }

  get endpoints() {
    return this._endpoints
  }

  set token(token) {
    this._apiInstance.defaults.headers.authorization = `bearer ${token}`
  }

  handleErrors() {
    this._apiInstance.interceptors.response.use(
      response => {
        return response
      },
      error => {
        const {config, response: status} = error
        console.error('error interceptor: ', error, config, status)
        // if (status === 401) {
        //   // if (!isRefreshing) {
        //   // isRefreshing = true;
        //   // refreshAccessToken().then(newToken => {
        //   //   isRefreshing = false;
        //   //   onRrefreshed(newToken);
        //   // });
        //   // }

        //   const retryOrigReq = new Promise((resolve, reject) => {
        //     // replace the token and send again the request
        //     // subscribeTokenRefresh(token => {
        //     // replace the expired token and retry
        //     // originalRequest.headers.Authorization = `Bearer ${token}`;
        //     resolve(this._apiInstance(originalRequest))
        //     // });
        //   })
        //   return retryOrigReq
        // }
        // return Promise.reject(error)
        throw error
      },
    )
  }
}

const apiClient = new API()

export {apiClient}
