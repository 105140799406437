import React from 'react'
import NxSpinner from 'components/NxSpinner/NxSpinner'

export function FullPageSpinner() {
  return (
    <div>
      <NxSpinner loading={true} />
    </div>
  )
}
