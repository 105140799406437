import KeycloakLib from 'keycloak-js'
let keycloakClient
if (!process.env.REACT_APP_ENV) {
  process.env.REACT_APP_ENV = 'development'
}
if (process.env.REACT_APP_ENV === 'production') {
  keycloakClient = KeycloakLib('/keycloak_prod.json')
} else if (process.env.REACT_APP_ENV === 'development') {
  keycloakClient = KeycloakLib(
    process.env.PUBLIC_URL + '/keycloak-frontend.json',
  )
}

export default keycloakClient
