import React, {useCallback} from 'react'
import {FullPageSpinner} from './components/lib'
/* import {BrowserRouter as Router, Route} from 'react-router-dom' */
import {useUser} from './context/user-context'
import {useAuth} from './context/auth-context'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import {StyledEngineProvider} from '@mui/material'

const AuthenticatedApp = React.lazy(() => import('./authenticated-app.js'))
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app.js'))

const theme = createTheme({
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 3px 6px #00000029',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
          '& input[type=number]::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '& input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        },
      },
    },
  },
})

function App() {
  const user = useUser()
  const {login} = useAuth()
  const onLogin = useCallback(login, [])

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <React.Suspense fallback={<FullPageSpinner />}>
            {user ? (
              <AuthenticatedApp />
            ) : (
              <UnauthenticatedApp onLogin={onLogin} />
            )}
          </React.Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  )
}

export default App
