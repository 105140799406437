import React from 'react'
import ReactDOM from 'react-dom'
import lottie from 'lottie-web/build/player/lottie_light_html.min.js'
import PropTypes from 'prop-types'

const nxjson =
  process.env.REACT_APP_ENV === 'development'
    ? process.env.PUBLIC_URL + '/nx.json'
    : '/nx.json'

//import './loader.css'

// const Spinner = () => (
//   <div id="loading">
//     <div className="sweet-loading">
//       <div className="loader">Loading...</div>
//     </div>
//   </div>
// )

// This code is necessary to createPortal in the tests
let portalRoot = document.getElementById('modal-root')
if (!portalRoot) {
  portalRoot = document.createElement('div')
  portalRoot.setAttribute('id', 'modal-root')
  document.body.appendChild(portalRoot)
}
function createLotti(el) {
  if (el) {
    lottie.loadAnimation({
      container: el, // Required
      path: nxjson, // Required
      renderer: 'svg', // Required
      loop: true, // Optional
      autoplay: true, // Optional
      name: 'Hello World', // Name for future reference. Optional.
    })
  }
}

const SpinnerLottie = () => (
  <div
    id="loading"
    ref={c => {
      createLotti(c)
    }}
  />
)

const SpinnerContentMemo = React.memo(function SpinnerContent(props) {
  const {loading} = props
  if (!loading) {
    return null
  }

  return ReactDOM.createPortal(
    <SpinnerLottie />,
    document.getElementById('modal-root'),
  )
})

SpinnerContentMemo.propTypes = {
  loading: PropTypes.bool,
}

// SpinnerContent.defaultProps = {
//   loading: false
// };

export default SpinnerContentMemo
