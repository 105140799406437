import React from 'react'
import PropTypes from 'prop-types'
import {toast} from 'react-toastify'
import {IoIosCloseCircleOutline} from 'react-icons/io'
import IconButton from '@mui/material/IconButton'

export const CloseButton = ({closeToast}) => (
  <IconButton edge="end" aria-label="toggle" onClick={closeToast} size="large">
    <IoIosCloseCircleOutline color="#676767" />
  </IconButton>
)

CloseButton.propTypes = {
  closeToast: PropTypes.func,
}

const ToastComponent = ({msg /* , closeToast,...rest */}) => {
  return <div>{msg}</div>
}

ToastComponent.propTypes = {
  closeToast: PropTypes.func,
  type: PropTypes.string,
  msg: PropTypes.string,
}

export default function useNotify() {
  const notify = (msg, options) => {
    return toast(<ToastComponent {...options} msg={msg} />, options)
  }
  return {notify}
}
