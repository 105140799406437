/* import client from './api-client' */
import keycloak from '../config/keycloakClient'
/* import socket from 'config/sockets' */
/* const localStorageKey = '__bookshelf_token__' */
import {apiClient} from 'utils/api'

/* function handleUserResponse({user: {token, ...user}}) {
  window.localStorage.setItem(localStorageKey, token)
  return user
} */

function getUser() {
  if (!keycloak.tokenParsed) {
    return Promise.resolve(null)
  }

  return new Promise(resolve => {
    keycloak
      .loadUserProfile()
      .then(response => {
        const roles = keycloak.tokenParsed.resource_access[
          'mipaciente-frontend'
        ].roles.concat(keycloak.tokenParsed.realm_access.roles)
        resolve({
          user: {
            ...response,
            roles,
          },
        })
      })
      .catch(() => {
        resolve(null)
      })
  })
}

function login(/* {username, password} */) {
  return new Promise((resolve, reject) => {
    keycloak
      .init({onLoad: 'login-required'})
      .then(authenticatedLocal => {
        apiClient.setToken(keycloak.token)
        resolve(authenticatedLocal)
        /* socket.io.opts.query = {
          token: keycloak.token,
        }
        socket.open() */
      })
      .catch(() => {
        reject(null)
      })

    keycloak.onTokenExpired = () => {
      console.warn('token expired')
      keycloak
        .updateToken(50)
        .then(refreshed => {
          if (refreshed) {
            console.info('Token was successfully refreshed')
            apiClient.setToken(keycloak.token)
          } else {
            console.info('Token is still valid')
          }
        })
        .catch(() => {
          console.error(
            'Failed to refresh the token, or the session has expired',
          )
        })
    }
  })

  //   return client('login', {body: {username, password}}).then(handleUserResponse)
}

/* function register({username, password}) {
  return client('register', {body: {username, password}}).then(
    handleUserResponse,
  )
} */

function logout() {
  // window.localStorage.removeItem(localStorageKey)
  keycloak.logout()
  return Promise.resolve()
}

function getToken() {
  return keycloak.token
}

export {login, logout, getToken, getUser}
