import React from 'react'
import {useAuth} from './auth-context'
import {intersection} from 'lodash'

const UserContext = React.createContext()

function UserProvider(props) {
  const {
    data: {user},
  } = useAuth()
  return <UserContext.Provider value={user} {...props} />
}
function useCheckPermission() {
  const context = React.useContext(UserContext)
  let hasPermission = () => false
  let hasSomePermission = () => false
  if (context) {
    const {roles} = context
    hasPermission = rol => roles.includes(rol)
    hasSomePermission = rolesToCheck =>
      intersection(roles, rolesToCheck).length > 0
  }
  return {hasPermission, hasSomePermission}
}

function useUser() {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`)
  }
  return context
}

export {UserProvider, useUser, useCheckPermission}
